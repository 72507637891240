import React from 'react'
import shortid from 'shortid'
import ActionBar from '../../molecules/ActionBar'
import Asset, { AssetImages } from './Asset'
import StyleGuide from './StyleGuide'

export default function PageArt({ content }: { content: any }) {
  return (
    <>
      <Asset asset={content.logo} />

      {content.assets.map((images: AssetImages) => (
        <Asset key={shortid.generate()} asset={images} />
      ))}

      <ActionBar actions={content.actions} />

      <StyleGuide />
    </>
  )
}
