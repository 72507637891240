import React from 'react'
import { SocialIcon } from '../atoms/SocialIcon'
import styles from './ActionBar.module.css'

export interface ActionItem {
  title: string
  description: string
  url: string
}

export default function ActionBar({
  actions,
  className
}: {
  actions: ActionItem[]
  className?: string
}) {
  return (
    <aside className={`${styles.actions} ${className && className}`}>
      {actions.map((item: ActionItem) => {
        const isDocs = item.url.includes('docs.oceanprotocol.com')
        const isGithub = item.url.includes('github.com')
        const icon = isDocs ? 'docs' : isGithub ? 'github' : item.title

        return (
          <a className={styles.action} href={item.url} key={item.title}>
            <SocialIcon icon={icon} />
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </a>
        )
      })}
    </aside>
  )
}
