import React from 'react'
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/Layout'
import PageArt from '../components/pages/art'

export default function PageGatsbyArt(props: PageProps) {
  const content = (props.data as any).allFile.edges[0].node.childPagesJson

  return (
    <Layout
      title={content.title}
      description={content.description}
      uri={props.uri}
      location={props.location}
      heroCenter
    >
      <PageArt content={content} />
    </Layout>
  )
}

export const contentQuery = graphql`
  query ArtPageQuery {
    allFile(filter: { relativePath: { eq: "pages/art.json" } }) {
      edges {
        node {
          childPagesJson {
            title
            description
            logo {
              title
              description
              items {
                publicURL
                extension
              }
            }
            actions {
              title
              description
              url
            }
            assets {
              title
              description
              items {
                publicURL
                extension
              }
            }
          }
        }
      }
    }
  }
`
