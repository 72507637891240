import React from 'react'
import styles from './StyleGuide.module.css'
import shortid from 'shortid'
import { getCSSCustomProps, hexToRgb } from '../../../utils'
import slugify from '@sindresorhus/slugify'
import Button from '../../atoms/Button'
import Container from '../../atoms/Container'
import Input from '../../atoms/Input'

export default function StyleGuide() {
  const cssCustomProps = getCSSCustomProps()
  const colors = cssCustomProps.filter(([key]: string[]) =>
    key.includes('--brand')
  )

  return (
    <div className={styles.styleguide}>
      <h2 id="styleguide">Style Guide</h2>

      <h3 className={styles.groupTitle}>Colors</h3>

      <ul className={styles.colors}>
        {colors.map(([key, value]: string[]) => (
          <li
            key={shortid.generate()}
            className={styles.swatch}
            style={{ background: value }}
            data-color={slugify(key)}
          >
            <h4 className={styles.swatchTitle}>{key.replace('--', '')}</h4>
            <div className={styles.swatchValue}>{value}</div>
            <div className={styles.swatchValue}>{hexToRgb(value)}</div>
          </li>
        ))}
      </ul>

      <h3 className={styles.groupTitle}>Typography</h3>

      <div className={styles.typography}>
        <Container narrow>
          <h1>
            Heading 1<br />
            Sharp Sans Display No. 1 Bold, always formatted in AP-Style Title
            Case
          </h1>
          <h2>Heading 2</h2>
          <h3>Heading 3</h3>
          <h4>Heading 4</h4>
          <h5>Heading 5</h5>

          <p>
            Body typography is set in Sharp Sans Pro Medium (500), and{' '}
            <strong>Sharp Sans Pro Bold (600)</strong>.
          </p>

          <p>
            As algae starfish, going down <em>in scorpionfish angelfish</em>.
            Yellow pseudochromis weasel shark shadow, darkness in deap ocean an,
            Motionless Wabash pigtoe Full moon. Angelfish love, applesnail
            Snaggletooth <strong>shark octopus bannerfish</strong>, beautiful
            Bursa trigger monster captive, a <a href="#">fishbowl possession</a>{' '}
            cat shark at parrotfish.
          </p>
          <p style={{ fontSize: 'var(--font-size-small)' }}>
            Bonnethead at puffer fish pipefish octopus threadfin. The crackin
            crazy Neon goby nurse shark moon. Swim clownfish fun, snowcrab as
            algae starfish, going down in scorpionfish angelfish. Fishies asian
            clam painted comber, king crab.
          </p>
          <p style={{ fontSize: 'var(--font-size-mini)' }}>
            Grape. Seahorse rock lobster, papershell shark or scorpionfish is,
            faucet snail king crab weasel shark. Zebra moray a banded grunt
            houndshark. Banana wrasse in yellow pseudochromis non mermaid
            parrotfish mystery snail. Mil.
          </p>
        </Container>
      </div>

      <h3 className={styles.groupTitle}>Buttons</h3>

      <Container narrow>
        <p className={styles.buttons}>
          <Button>Default</Button>
          <Button size="small">Default</Button>
        </p>

        <p className={styles.buttons}>
          <Button style="primary">Primary</Button>
          <Button style="primary" size="small">
            Primary
          </Button>
        </p>

        <p className={styles.buttons}>
          <Button style="ghost">Ghost</Button>
          <Button style="ghost" size="small">
            Ghost
          </Button>
        </p>

        <p className={styles.buttons}>
          <Button style="text">Text</Button>
          <Button style="text" size="small">
            Text
          </Button>
        </p>
      </Container>

      <h3 className={styles.groupTitle}>Ocean Creative</h3>
      <Container narrow>
        <h5>
          For all Ocean Protocol visual and video assets, please click{' '}
          <a href="https://drive.google.com/drive/folders/1gfzH1xcCEaME5zqUk3EfkGGTVkpizaGe?usp=sharing">
            here.
          </a>
        </h5>
      </Container>

      <h3 className={styles.groupTitle}>Form Elements</h3>

      <Container narrow>
        <Input name="cool" label="My Cool Input" help="Help is on the way." />
        <Input
          name="select"
          label="My Cool Select"
          type="select"
          options={['Choose This']}
          help="Help is on the way."
        />
        <Input
          name="radio"
          label="My Cool Radio"
          type="radio"
          options={['Choose Me', 'Or Choose Me']}
          help="Help is on the way."
        />
        <Input
          name="checkbox"
          label="My Cool Checkbox"
          type="checkbox"
          options={['Choose That', 'And Choose Me']}
          help="Help is on the way."
        />
      </Container>
    </div>
  )
}
