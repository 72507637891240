import React from 'react'
import classNames from 'classnames/bind'
import slugify from '@sindresorhus/slugify'
import shortid from 'shortid'
import Button from '../../atoms/Button'
import styles from './Asset.module.css'
import { markdownify } from '../../../utils'

const cx = classNames.bind(styles)

export interface AssetImage {
  publicURL: string
  extension: string
}

export interface AssetImages {
  title: string
  description?: string
  items: AssetImage[][]
}

const Image = ({ i, image }: { i: number; image: AssetImage }) => {
  if (!image || !image.publicURL) return null

  // switch background for some assets
  const isDarkBackground =
    image.publicURL.includes('-full') || image.publicURL.includes('logo-white')

  return (
    <>
      {/* Use the first item as the display item, assuming it's SVG */}
      {i === 0 && (
        <div className={`${styles.image} ${isDarkBackground && styles.dark}`}>
          <img src={image.publicURL} />
        </div>
      )}

      {/* Download button for all asset images */}
      <Button
        className={styles.button}
        style="text"
        size="small"
        href={image.publicURL}
        download
      >
        {image.extension}
      </Button>
    </>
  )
}

export default function Asset({ asset }: { asset: AssetImages }) {
  const slug = slugify(asset.title, {
    decamelize: false,
    separator: '_'
  })

  const classes = cx({
    asset: true,
    [slug]: true
  })

  return (
    <div className={classes}>
      <h3 className={styles.title}>{asset.title}</h3>
      <div className={styles.description}>{markdownify(asset.description)}</div>

      <div className={styles.images}>
        {asset.items.map((images: AssetImage[]) => (
          <figure key={shortid.generate()}>
            {images.map((image: AssetImage, i: number) => (
              <Image key={shortid.generate()} i={i} image={image} />
            ))}
          </figure>
        ))}
      </div>
    </div>
  )
}
